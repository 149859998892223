// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import moment from "moment";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
import { allapiAction } from "../Redux/common/action";
var _ = require("lodash");

function BattelDetails() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  console.log(id);

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    hendleTojoin();

    return () => {};
  }, []);
  const dispatch = useDispatch();
  const [battelDetails, setBattelDetails] = useState({});
  const hendleTojoin = async (e) => {
    // setfirst(e?._id);
    const response = await dispatch(allapiAction.battelDetails({ _id: id }));
    // dispatch(allapiAction.getbattellist("classicmanual"));
    console.log(response);
    setBattelDetails(response?.data);
  };
  const getuserList = battelDetails?.listSlot?.filter((data) => {
    return data?.playJoin == "true";
  });
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area ">
            <div className="bg-white">
              <div class="container-fluid mt-5">
                <div class="card shadow">
                  <div class="card-header py-3">
                    <img
                      src="https://via.placeholder.com/100"
                      alt="Win27 Logo"
                      class="mb-2"
                      style={{ width: "60px" }}
                    />
                    <h6 class="mb-0 text-primary">{battelDetails?.roomName}</h6>
                  </div>
                  <div class="card-body">
                    <div>
                      <div className="solosd">
                        <strong>Type:</strong> Solo
                      </div>
                      <div className="solosd">
                        <strong>Version:</strong> TPP
                      </div>
                      <div className="solosd">
                        <strong>Map:</strong> Bermuda
                      </div>
                      <div className="solosd">
                        <strong>Match Type:</strong>{" "}
                        {battelDetails?.entryFee > 0 ? "Paid" : "Free"}
                      </div>
                      <div className="solosd">
                        <strong>Entry Fee:</strong>{" "}
                        <span>{battelDetails?.entryFee} 🪙</span>
                      </div>
                      <div className="solosd">
                        <strong>Match Schedule:</strong>{" "}
                        {moment(battelDetails?.startDateTime)?.format(
                          "DD/MM/YYYY hh:MM A"
                        )}{" "}
                        {/* 24/12/2024 at 06:00 PM */}
                      </div>
                    </div>

                    <h6 class="text-primary mb-3">Prize Details</h6>
                    <ul class="list-group">
                      <li class="list-group-item prize-item">
                        <span>1st Place:</span> <strong>INR 70</strong>
                      </li>
                      <li class="list-group-item prize-item">
                        <span>2nd Place:</span> <strong>INR 60</strong>
                      </li>
                      <li class="list-group-item prize-item">
                        <span>3rd Place:</span> <strong>INR 50</strong>
                      </li>
                      <li class="list-group-item prize-item">
                        <span>4th - 8th Place:</span> <strong>INR 40</strong>
                      </li>
                      <li class="list-group-item prize-item">
                        <span>9th - 10th Place:</span> <strong>INR 35</strong>
                      </li>
                    </ul>

                    <div className="row mt-2">
                      <div className="col-6">
                        <h6 class="text-primary mb-3">Participants</h6>
                      </div>
                      <div className="col-6 text-end">
                        <i class="bi bi-arrow-clockwise"></i> Refresh
                      </div>
                      <div className="col-12">
                        <ul class="list-group">
                          {getuserList &&
                            getuserList?.map((data, i) => {
                              return (
                                <li class="list-group-item prize-item">
                                  <span>{i + 1}. </span>
                                  {data?.userName}
                                </li>
                              );
                            })}

                          {/* <li class="list-group-item prize-item">
                            <span>2. </span>dsf
                          </li> */}
                        </ul>
                      </div>
                    </div>

                    <div class="text-center mt-4">
                      <button class="btn btn-spectate btn-lg px-5">
                        SPECTATE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default BattelDetails;
