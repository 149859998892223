// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import moment from "moment";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
import { allapiAction } from "../Redux/common/action";
var _ = require("lodash");

function Wallet2() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  const get_transaction = useSelector((state) =>
    state?.allapi?.get_transaction ? state?.allapi?.get_transaction : []
  );

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // battel_List
    // dispatch(allapiAction.getbattellistUser());
    dispatch(allapiAction.getuserTransation(1));
    return () => {};
  }, []);

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div
            className="main-area "
            style={{ paddingTop: "80px", background: "#DDDDDD" }}
          >
            <div className="container-fluid lottery">
              <h1 class="text-center mb-4">Transaction History</h1>

              <div class="row ">
                <div class="col-md-12">
                  {get_transaction &&
                    get_transaction?.map((data, i) => {
                      return (
                        <div class="transaction-card">
                          <div class="date">
                            {moment(data?.createdAt)?.format("DD-MM-YYYY ")}
                            {/* 29-11-2024 */}
                          </div>
                          <div className="trmt">
                            <div className="row justify-content-evenly">
                              <div className="col-6">
                                <div class="transaction-type">
                                  {data?.description}
                                  {/* Redeem From UPI */}
                                </div>
                                <div class="transaction-id">
                                  #id: {data?.transaction_id}
                                </div>
                              </div>
                              <div className="col-6 text-end">
                                <div class="amount">
                                  {data?.type == "cr" ? "+" : "-"}{" "}
                                  {data?.amount} <span class="coin">🪙</span>
                                </div>

                                <div class="status successful">Successful </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {/* <div class="transaction-card">
                    <div class="date">29-11-2024</div>
                    <div className="trmt">
                      <div className="row justify-content-evenly">
                        <div className="col-6">
                          <div class="transaction-type">Redeem From UPI</div>
                          <div class="transaction-id">#id: PCDB111700</div>
                        </div>
                        <div className="col-6 text-end">
                          <div class="amount">
                            -50 <span class="coin">🪙</span>
                          </div>

                          <div class="status successful">Successful </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="transaction-card">
                    <div class="date">28-11-2024</div>
                    <div className="trmt">
                      <div className="row justify-content-evenly">
                        <div className="col-6">
                          <div class="transaction-type">Deposit</div>
                          <div class="transaction-id">#id: PCDB111700</div>
                        </div>
                        <div className="col-6 text-end">
                          <div class="amount">
                            -50 <span class="coin">🪙</span>
                          </div>

                          <div class="status successful">Successful </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div class="transaction-card">
                    <div class="date">28-11-2024</div>
                    <div class="transaction-type">Deposit</div>
                    <div class="amount">
                      +100 <span class="coin">🪙</span>
                    </div>
                    <div class="transaction-id">#id: PCDB111701</div>
                    <div class="status successful">Successful</div>
                  </div> */}
                  {/* <div class="transaction-card">
                    <div class="date">27-11-2024</div>
                    <div class="transaction-type">Withdraw</div>
                    <div class="amount">
                      -30 <span class="coin">🪙</span>
                    </div>
                    <div class="transaction-id">#id: PCDB111702</div>
                    <div class="status failed">Failed</div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Wallet2;
