import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import Slider from "react-slick";
import { URL } from "../Redux/common/url";
import api from "../Redux/common/api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import i18next from "i18next";
import Languageoption from "./language-dropdown";
import { useTranslation } from "react-i18next";
import RightContainer from "./RightContainer";
function Profile2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alredy, setalredy] = useState(false);
  const [userName, setUserName] = useState(false);
  const [userDetails, setuserDetails] = useState({});
  const [userDetails2, setuserDetails2] = useState({});
  const [file, setFile] = useState(false);
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const hendleTochangeProfile = (e) => {
    const { name, value } = e?.target;
    setuserDetails({ ...userDetails, [name]: value });
  };
  const hendleTochangeProfile2 = (e) => {
    const { name, value } = e?.target;
    setuserDetails2({ ...userDetails2, [name]: value });
  };

  const [selectlan, setselectlan] = useState("en");

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  const get_metrics = useSelector((state) =>
    state?.allapi?.get_metrics ? state?.allapi?.get_metrics : {}
  );
  console.log(userDetails);

  useEffect(() => {
    setuserDetails(profile?.users);
    return () => {};
  }, [profile]);
  useEffect(() => {
    dispatch(allapiAction.metricsUser());
    return () => {};
  }, []);
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => {
    setShow(true);
    setUserName(profile?.users?.uid);
  };
  const handleShow1 = () => {
    setShow1(true);
    // setUserName(profile?.users?.uid);
  };
  // profile

  const hendleTochangeuserName = async (e) => {
    const token = await localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    // dispatch(loading(true));
    setUserName(e);
    const response = await api.post(`${URL.checkuserName}`, { uid: e }, config);

    if (response?.data?.succes) {
      setalredy(true);
    } else {
      setalredy(false);
    }
  };

  const logout = async () => {
    if (typeof window !== "undefined") {
      await localStorage.clear();
    }

    navigate("/login");
  };

  const hendleToSubmit = () => {
    if (alredy) {
      toast.error("already exist");
    } else if (userName?.length <= 4) {
      toast.error("invalid ");
    } else {
      setShow(false);
      dispatch(allapiAction.edituserId({ uid: userName }));
      return () => {};
    }
  };
  const hendleToSubmitUpdate = () => {
    if (!userDetails?.firstName) {
      toast.error("please enter first name");
    } else if (!userDetails?.lastName) {
      toast.error("please enter last name ");
    } else if (!userDetails?.userName) {
      toast.error("please enter user name ");
    } else if (!userDetails?.email) {
      toast.error("please enter email ");
    } else {
      //   setShow(false);
      dispatch(allapiAction.edituserDetails(userDetails));
      return () => {};
    }
  };

  console.log(userDetails2);

  const hendleToPasswordSubmitUpdate = async () => {
    if (!userDetails2?.old_pass) {
      toast.error("please enter old password");
    } else if (!userDetails2?.new_pass) {
      toast.error("please enter new password ");
    } else if (!userDetails2?.confirmPassword) {
      toast.error("please enter confirm password ");
    } else if (userDetails2?.new_pass !== userDetails2?.confirmPassword) {
      toast.error("password not match ");
    } else {
      //   setShow(false);
      const response = await dispatch(
        allapiAction.selfchangepassword(userDetails2)
      );
      if (response?.success) {
        setuserDetails2({ old_pass: "", new_pass: "", confirmPassword: "" });
      }

      return () => {};
    }
  };

  // editprofileImage

  const hendletoImageupload = () => {
    setShow1(false);
    const data1 = new FormData();

    data1.append("image", file);
    dispatch(allapiAction.editprofileImage(data1));
    return () => {};
  };

  useEffect(() => {
    // console.log(e?.target?.value);
    const lang = localStorage.getItem("lang");
    if (lang) {
      setselectlan(lang);
      // i18next.changeLanguage(lang);
    }
  }, []);

  const handleClick = (e) => {
    setselectlan(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        {/* <div id="menufade" onclick="closeMenu()" className=""></div> */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alredy && <p style={{ color: "red" }}>allready exist</p>}
            {userName?.length <= 4 && (
              <p style={{ color: "red" }}>not valid </p>
            )}
            <input
              value={userName}
              className="form-control"
              onChange={(e) => {
                hendleTochangeuserName(e?.target?.value);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={hendleToSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="file"
              name="screenshot"
              className="form-control"
              required=""
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={hendletoImageupload}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area secpr" style={{ paddingTop: "60px" }}>
            <div class="profile-container">
              <div class="profile-headersv">
                <img
                  src="https://via.placeholder.com/80"
                  alt="Profile Picture"
                />
                {/* <h3>My Profile</h3> */}
              </div>

              <div class="profile-stats">
                <div style={{ borderRight: "1px solid white", width: "100%" }}>
                  <p className="text-white">0</p>
                  <small className="text-white">Matches Played</small>
                </div>
                <div style={{ borderRight: "1px solid white", width: "100%" }}>
                  <p className="text-white">0</p>
                  <small className="text-white">Total Kills</small>
                </div>
                <div style={{ width: "100%" }}>
                  <p className="text-white">0</p>
                  <small className="text-white">PlayCoin Won</small>
                </div>
              </div>

              {/* <form> */}
              <h5 className="text-white">Edit Profile</h5>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="First Name"
                  name="firstName"
                  onChange={(e) => {
                    hendleTochangeProfile(e);
                  }}
                  value={userDetails?.firstName}
                />
              </div>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={(e) => {
                    hendleTochangeProfile(e);
                  }}
                  value={userDetails?.lastName}
                />
              </div>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  //   value="shzib"
                  name="userName"
                  onChange={(e) => {
                    hendleTochangeProfile(e);
                  }}
                  value={userDetails?.userName}
                />
              </div>
              <div class="mb-3">
                <div class="input-group">
                  <span class="input-group-text">+91</span>
                  <input
                    type="text"
                    class="form-control"
                    name="number"
                    placeholder="Phone number"
                    // onChange={(e) => {
                    //   hendleTochangeProfile(e);
                    // }}
                    value={userDetails?.number}
                  />
                </div>
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  name="email"
                  onChange={(e) => {
                    hendleTochangeProfile(e);
                  }}
                  value={userDetails?.email}
                />
              </div>
              {/* <div class="mb-3">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Date of Birth"
                />
              </div> */}
              <button
                type="button"
                class="btn btn-purple w-100"
                onClick={() => {
                  hendleToSubmitUpdate();
                }}
              >
                SAVE
              </button>
              {/* </form> */}

              <hr class="my-4" />

              {/* <form> */}
              <h5 className="text-white">Reset Password</h5>
              <div class="mb-3">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Old Password"
                  name="old_pass"
                  onChange={(e) => {
                    hendleTochangeProfile2(e);
                  }}
                  value={userDetails2?.old_pass}
                />
              </div>
              <div class="mb-3">
                <input
                  type="password"
                  class="form-control"
                  placeholder="New Password"
                  name="new_pass"
                  onChange={(e) => {
                    hendleTochangeProfile2(e);
                  }}
                  value={userDetails2?.new_pass}
                />
              </div>
              <div class="mb-3">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Reset New Password"
                  name="confirmPassword"
                  onChange={(e) => {
                    hendleTochangeProfile2(e);
                  }}
                  value={userDetails2?.confirmPassword}
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  hendleToPasswordSubmitUpdate();
                }}
                class="btn btn-purple w-100"
              >
                RESET
              </button>
              {/* </form> */}
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
        {/* <div className="rightContainer">
      <div className="rcBanner flex-center">
        <picture className="rcBanner-img-container">
          <img
            src="../assest/image/Header_profile.jpeg"
            alt=""
          />
        </picture>
        <div className="rcBanner-text">
           RK Ludo King <span className="rcBanner-text-bold">Win Real Cash!</span>
        </div>
        <div className="rcBanner-footer">
          For best experience, open&nbsp;<a
            href="#!"
            style="
              color: rgb(44, 44, 44);
              font-weight: 500;
              text-decoration: none;
            "
            > RK Ludo King.In</a
          >&nbsp;on&nbsp;<img
            src="../assest/image/global-chrome.png"
            alt=""
          />&nbsp;chrome mobile
        </div>
      </div>
    </div> */}
      </div>
    </div>
  );
}

export default Profile2;
