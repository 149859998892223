// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
import { toast } from "react-toastify";
import { allapiAction } from "../Redux/common/action";
var _ = require("lodash");

function Register() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const dispatch = useDispatch();
  const [logbtn, setlogbtn] = useState(true);

  const [userData, setuserData] = useState({});
  const [passwordShow, setpasswordShow] = useState("password");

  console.log(userData);

  //   const hendleToCh = (e)

  const hendleToChange = (e) => {
    setuserData({ ...userData, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const hendeleToLogin = async () => {
    if (!userData?.firstName) {
      toast.error("please enter first name");
    } else if (!userData?.lastName) {
      toast.error("please enter last name");
    } else if (!userData?.userName) {
      toast.error("please enter Username");
    } else if (!userData?.email) {
      toast.error("please enter email");
    } else if (!userData?.number) {
      toast.error("please enter number");
    } else if (!userData?.password) {
      toast.error("please enter password");
    } else {
      setlogbtn(false);

      const logindata = await dispatch(allapiAction.register(userData));

      setlogbtn(true);
      if (logindata?.success) {
        //   setBox("1");
        navigate("/login");
      }

      return () => {};
    }
  };

  return (
    <div>
      <div id="root ">
        {/* <SiderBar /> */}

        <div className="leftContainer newregister">
          <div className="main-area" style={{ paddingTop: "80px" }}>
            <div class="register-card text-center">
              <h4 class="mb-4">REGISTER</h4>
              <img src="https://via.placeholder.com/50" alt="Logo" />
              <p class="my-2">or SignUp with</p>
              <button class="btn btn-outline-secondary btn-google">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                  alt="Google Logo"
                  class="me-2"
                  style={{ width: "20px", height: "20px" }}
                />
                Google
              </button>
              {/* <form class="mt-3"> */}
              <div class="row mb-3">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="First Name"
                    name="firstName"
                    onChange={hendleToChange}
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    name="lastName"
                    onChange={hendleToChange}
                  />
                </div>
              </div>
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="userName"
                  name="userName"
                  onChange={hendleToChange}
                />
              </div>
              <div class="mb-3">
                <div class="input-group">
                  <span class="input-group-text">+91</span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone number"
                    //   value={91}
                    name="number"
                    onChange={hendleToChange}
                  />
                </div>
              </div>
              <div class="mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  name="email"
                  onChange={hendleToChange}
                />
              </div>
              <div class="mb-3">
                <div class="input-group">
                  <input
                    type={passwordShow}
                    class="form-control"
                    placeholder="Password"
                    name="password"
                    onChange={hendleToChange}
                  />
                  <button
                    class="btn btn-outline-secondary"
                    onClick={() =>
                      setpasswordShow(
                        passwordShow == "password" ? "text" : "password"
                      )
                    }
                  >
                    {passwordShow == "password" ? "SHOW" : "HIDE"}
                  </button>
                </div>
              </div>
              <div class="mb-3 promo-section">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Promo Code (Optional)"
                />
                <button class="btn btn-warning">APPLY</button>
              </div>
              {logbtn && (
                <button
                  type="submit"
                  class="btn btn-primary w-100"
                  onClick={hendeleToLogin}
                >
                  SIGN UP
                </button>
              )}
              {/* </form> */}
              <footer class="mt-3">
                By Registering, I agree to Win27's <br />
                <a href="#">Terms and Conditions</a> and{" "}
                <a href="#">Privacy Policy</a>
              </footer>
              <p class="mt-2">
                Already have an account? <Link to="/login">LOGIN</Link>
              </p>
            </div>

            <div className=""></div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Register;
