// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
var _ = require("lodash");

function Setting() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div
            className="main-area "
            style={{ paddingTop: "60px", background: "#DDDDDD" }}
          >
            <div class="profile-header position-relative">
              <img src="https://via.placeholder.com/80" alt="Profile Image" />
              {/* <h4 class="mt-3">Sketch Artist</h4> */}
            </div>
            <div className="container-fluid">
              <div class="profile-headernwmw">
                {/* <img src="https://via.placeholder.com/80" alt="Profile Image" /> */}
                <h4 class="mt-3">
                  {profile?.users?.firstName + " " + profile?.users?.lastName}
                </h4>
              </div>

              <div class="container">
                <div class="stats-card row text-center">
                  <div class="col-4 stats-item">
                    <h5>0</h5>
                    <p>Matches Played</p>
                  </div>
                  <div class="col-4 stats-item">
                    <h5>0</h5>
                    <p>Total Kills</p>
                  </div>
                  <div class="col-4 stats-item">
                    <h5>
                      <span class="text-warning">🪙</span> 0
                    </h5>
                    <p>PlayCoin Won</p>
                  </div>
                </div>

                <div class="mt-4">
                  <div class="menu-item" onClick={() => navigate("/profile")}>
                    <span>
                      <i class="bi bi-person-circle me-2"></i> My Profile
                    </span>
                    <i class="bi bi-chevron-right"></i>
                  </div>
                  <div class="menu-item" onClick={() => navigate("/my-wallet")}>
                    <span>
                      <i class="bi bi-wallet2 me-2"></i> My Wallet
                    </span>
                    <i class="bi bi-chevron-right"></i>
                  </div>
                  <div class="menu-item">
                    <span>
                      <i class="bi bi-bar-chart-line me-2"></i> My Statistics
                    </span>
                    <i class="bi bi-chevron-right"></i>
                  </div>
                  <div class="menu-item">
                    <span>
                      <i class="bi bi-trophy me-2"></i> Top Players
                    </span>
                    <i class="bi bi-chevron-right"></i>
                  </div>
                  <div class="menu-item">
                    <span>
                      <i class="bi bi-bell me-2"></i> Notifications
                    </span>
                    <i class="bi bi-chevron-right"></i>
                  </div>
                  <div class="menu-item">
                    <span>
                      <i class="bi bi-headset me-2"></i> Contact Us
                    </span>
                    <i class="bi bi-chevron-right"></i>
                  </div>
                  <div class="menu-item toggle-switch">
                    <span>
                      <i class="bi bi-exclamation-triangle me-2"></i> Importance
                      Notice
                    </span>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="importanceNotice"
                        checked
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Setting;
