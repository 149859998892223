// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
var _ = require("lodash");

function Lottery() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div
            className="main-area "
            style={{ paddingTop: "80px", background: "#DDDDDD" }}
          >
            <div className="container-fluid lottery">
              <ul class="nav nav-tabs justify-content-center mb-4">
                <li class="nav-item">
                  <button
                    class="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#ongoing-tab"
                  >
                    ONGOING
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#results-tab"
                  >
                    RESULTS
                  </button>
                </li>
              </ul>

              <div class="tab-content">
                <div class="tab-pane fade show active" id="ongoing-tab">
                  <div class="lottery-card">
                    <img
                      src="https://via.placeholder.com/150x150"
                      alt="Lottery Banner"
                    />

                    <div class="card-body">
                      <h5 class="title">LOTTERY DHAMAKA #1</h5>
                      <p class="details">
                        <span>Draw On:</span> 11/08/2024
                        <br />
                        <span>Won Prize:</span> 250
                        <br />
                        <span>Won By:</span>{" "}
                        <a href="#" style={{color: "#3498db"}}>
                          prince bhai
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" id="results-tab">
                  <p class="text-center">No results available yet!</p>
                </div>
              </div>
            </div>

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Lottery;
