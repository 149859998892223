// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
var _ = require("lodash");

function MyWallet() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );
  console.log(profile);
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "80px" }}>
            <div className="container-fluid lottery">
              <div>
                <div className="row justify-content-center">
                  <div className="col-12 text-center  mt-4 mb-4">
                    <span class="coin1">
                      🪙<span className="text-white">{profile?.wallets?.closeBal}</span>{" "}
                    </span>
                  </div>
                  <div className="col-4  text-center">
                    <div
                      className="icons d-flex justify-content-center align-items-center flex-column"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        width: "100px",
                        height: "100px",
                      }}
                      //   onClick={() => {
                      //     navigate("/upcoming");
                      //   }}
                    >
                      <span class="coin">
                        🪙<span className="text-black">{profile?.wallets?.closeBal}</span>{" "}
                      </span>
                      <p
                        style={{
                          color: "#000",
                          fontSize: "15px",
                          textAlign: "center",
                          marginBottom: "0px",
                          marginTop: "10px",
                        }}
                      >
                        Deposited
                      </p>
                    </div>
                  </div>
                  <div className="col-4  text-center">
                    <div
                      className="icons d-flex justify-content-center align-items-center flex-column"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        width: "100px",
                        height: "100px",
                      }}
                      //   onClick={() => {
                      //     navigate("/upcoming");
                      //   }}
                    >
                      <span class="coin">
                        🪙<span className="text-black">{profile?.wallets?.winningBal}</span>{" "}
                      </span>
                      <p
                        style={{
                          color: "#000",
                          fontSize: "15px",
                          textAlign: "center",
                          marginBottom: "0px",
                          marginTop: "10px",
                        }}
                      >
                        Winning
                      </p>
                    </div>
                  </div>
                  <div className="col-4  text-center">
                    <div
                      className="icons d-flex justify-content-center align-items-center flex-column"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        width: "100px",
                        height: "100px",
                      }}
                      //   onClick={() => {
                      //     navigate("/upcoming");
                      //   }}
                    >
                      <span class="coin">
                        🪙<span className="text-black">{profile?.users?.totalBonus}</span>{" "}
                      </span>
                      <p
                        style={{
                          color: "#000",
                          fontSize: "15px",
                          textAlign: "center",
                          marginBottom: "0px",
                          marginTop: "10px",
                        }}
                      >
                        Bonus
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="myWalletC">
                  <div className="myWalletmain p-3">
                    <h6>My Wallet</h6>
                    <div className="row justify-content-center">
                      <div className="col-12"></div>
                      <div className="col-4">
                        <div
                          className="icons d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100px",
                            height: "100px",
                            boxShadow:
                              " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          // onClick={() => {
                          //   navigate("/wallet-new");
                          // }}
                        >
                          {/* <i
                            className="fa fa-trophy fa-3x"
                            aria-hidden="true"
                            style={{ color: "red" }}
                          ></i> */}
                          <i class="bi bi-wallet2 me-2"></i>
                          <p
                            style={{
                              color: "#000",
                              fontSize: "13px",
                              textAlign: "center",
                              marginBottom: "0px",
                              marginTop: "10px",
                            }}
                          >
                            Add Coins
                          </p>
                        </div>
                      </div>
                      <div className="col-4">
                        <div
                          className="icons d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100px",
                            height: "100px",
                            boxShadow:
                              " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          //   onClick={() => {
                          //     navigate("/upcoming");
                          //   }}
                        >
                          {/* <i
                            className="fa fa-trophy fa-3x"
                            aria-hidden="true"
                            style={{ color: "red" }}
                          ></i> */}
                          <i class="bi bi-wallet2 me-2"></i>
                          <p
                            style={{
                              color: "#000",
                              fontSize: "13px",
                              textAlign: "center",
                              marginBottom: "0px",
                              marginTop: "10px",
                            }}
                          >
                            Redeem Coins
                          </p>
                        </div>
                      </div>
                      <div className="col-4">
                        <div
                          className="icons d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            width: "100px",
                            height: "100px",
                            boxShadow:
                              " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          onClick={() => {
                            navigate("/wallet-new");
                          }}
                        >
                          {/* <i
                            className="fa fa-trophy fa-3x"
                            aria-hidden="true"
                            style={{ color: "red" }}
                          ></i> */}
                          <i class="bi bi-wallet2 me-2"></i>
                          <p
                            style={{
                              color: "#000",
                              fontSize: "13px",
                              textAlign: "center",
                              marginBottom: "0px",
                              marginTop: "10px",
                            }}
                          >
                            Transaction
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default MyWallet;
