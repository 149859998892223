// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
var _ = require("lodash");

function Earn() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div
            className="main-area "
            style={{ paddingTop: "80px", background: "#DDDDDD" }}
          >
         <div className="container-fluid earn">


         <div class="card-container gradient-refer">
      <img src="https://via.placeholder.com/50" alt="Refer Icon" />
      <h5>Refer and Earn</h5>
      <p>Invite your friends to the app and Earn Huge!</p>
    </div>
    <div class="card-container gradient-watch">
      <img src="https://via.placeholder.com/50" alt="Watch Icon" />
      <h5>Watch and Earn</h5>
      <p>Watch reward video daily and Earn Huge!</p>
    </div>
    <div class="card-container gradient-lucky">
      <img src="https://via.placeholder.com/50" alt="Lucky Icon" />
      <h5>Lucky Draw</h5>
      <p>Buy lottery ticket and try your luck!</p>
    </div>
    <div class="card-container gradient-buy">
      <img src="https://via.placeholder.com/50" alt="Buy Icon" />
      <h5>Buy Products</h5>
      <p>Get amazing products here in low cost!</p>
    </div>
    <div class="card-container gradient-play">
      <img src="https://via.placeholder.com/50" alt="Play Icon" />
      <h5>Play and Earn</h5>
      <p>Play pubg tournament daily and Earn Huge!</p>
    </div>
        
         </div>

            <div className="">
              <FooterNew />
            </div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Earn;
