import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { allapiAction } from "../Redux/common/action";
import { SIDERBAR_BOS } from "../Redux/common/constant";
import axios from "axios";
import FooterNew from "./FooterNew";

function Header() {
  const [first, setfirst] = useState(false);

  const dispatch = useDispatch();
  const otpBoxAction = (data) => {
    return { type: SIDERBAR_BOS, payload: data };
  };

  // const get_message = useSelector((state) =>
  //   state?.allapi?.get_message ? state?.allapi?.get_message : {}
  // );

  // console.log(get_message);

  // profile

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

  useEffect(() => {
    dispatch(allapiAction.getprofile({}));
    dispatch(allapiAction.getmessageuser({}));
    //     axios.get('http://localhost:5700/publicApi/getmessageuser')
    // .then(response => console.log(response.data))
    // .catch(error => console.error('Error fetching data:', error));

    // fetch('http://localhost:5700/publicApi/getmessageuser')
    // .then(response => response.json())
    // .then(data => console.log(data))
    // .catch(error => console.error('Error fetching data:', error));

    return () => {};
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     dispatch(allapiAction.getprofile({}));
  //     return () => {};
  //   }, 5000);

  //   // const interval = setInterval(() => {
  //   //   setCount(count => count + 1);
  //   // }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  const hendleSideBar = (e) => {
    dispatch(otpBoxAction(e));
  };
  const handleClick = (e) => {
    console.log(e?.target?.value);
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18next.changeLanguage(lang);
    }
  };
  useEffect(() => {
    handleClick();
  }, []);
  const totalbalance =
    profile?.wallets?.closeBal + profile?.wallets?.winningBal;
  return (
    <div>
      <div className="headerContainerms">
        <div>
          {/* {get_message?.first && (
            <div
              className={
                get_message?.first?.priority == "red"
                  ? "messagebox1"
                  : "messagebox2"
              }
            >
              <div class="collapseCard-text">{get_message?.first?.message}</div>
            </div>
          )} */}
        </div>

        <div className="position-relative">
          <div className="headerContainer ">
            <picture className="sideNavIcon  mr-2">
              <img
                src="../assest/image/lg1.png"
                // onClick={() => {
                //   hendleSideBar(true);
                // }}
                alt=""
              />
            </picture>

            <Link to={"/"} style={{ textDecoration: "none" }}>
              {/* <picture className="ms-2 navLogo d-flex">
                <img src="../assest/image/logo2.png" alt="" />
              </picture> */}
              <p style={{ color: "#fff", textAlign: "center" }}>
                Welcome Back,
                <br />
                <span style={{ fontSize: "18px" }}>Win27</span>
              </p>
            </Link>

            <div className="menu-items">
              <div id="walletBalance">
                <div className="menu-items">
                  {/* add-funds */}
                  {/* <Link to={"/add-funds"} className="box"> */}
                  <Link to={"/my-wallet"} className="box">
                    <picture className="moneyIcon-container">
                      <img src="../assest/image/global-rupeeIcon.png" alt="" />
                    </picture>
                    <div className="mt-2 ms-1">
                      {/* <div className="moneyBox-header">Cash</div> */}
                      <div className="moneyBox-text">
                        ₹{totalbalance.toFixed(2)}
                      </div>
                    </div>
                    {/* <picture className="moneyBox-add">
                      <img src="../assest/image/global-addSign.png" alt="" />
                    </picture> */}
                  </Link>

                  {/* &nbsp;
                  <Link className="box" to="/withdrawal-request">
                    <picture className="moneyIcon-container">
                      <img src="../assest/image/reward_red.png" alt="" />
                    </picture>
                    <div className="mt-1 ms-1">
                      <div className="moneyBox-header">Winning</div>
                      <div className="moneyBox-text">
                        ₹{profile?.wallets?.winningBal}
                      </div>
                    </div>
                    
                  </Link> */}
                </div>
                <span className="mx-5"> </span>
              </div>
            </div>

            {/* <span className="mx-5"></span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
