// import translate from "google-translate-api";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import RightContainer from "./RightContainer";
// import { translateText } from "./Translate";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import Languageoption from "./language-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import Slider from "react-slick";
import FooterNew from "../Layout/FooterNew";
import { allapiAction } from "../Redux/common/action";
import { toast } from "react-toastify";
var _ = require("lodash");

function LoginNew() {
  const { t, i18n } = useTranslation();

  const handleClick = (e) => {
    console.log(e?.target?.value);
    localStorage.setItem("lang", e?.target?.value);
    i18next.changeLanguage(e?.target?.value);
  };

  const get_message = useSelector((state) =>
    state?.allapi?.get_message ? state?.allapi?.get_message : {}
  );

  console.log(1 + 2);
  const ab = [1, 2, 3, 4, 5, 6, 7];
  const abc = [{ name: "" }];
  const as = _.chunk(ab, 2);

  console.log(as);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [userData, setuserData] = useState({});
  const [passwordShow, setpasswordShow] = useState("password");

  console.log(userData);

  //   const hendleToCh = (e)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hendleToChange = (e) => {
    setuserData({ ...userData, [e.target.name]: e.target.value });
  };
  const hendelotpVerfy = () => {
    if (!userData?.number) {
      toast.error("please enter phone number");
      return;
    }
    if (userData?.number?.length !== 10) {
      toast.error("please enter valid phone number");
      return;
    }
    if (!userData?.password) {
      toast.error("please enter password");
      return;
    }
    dispatch(allapiAction.signinUser(userData, navigate));
    // setBox("1");
    return () => {};
  };
  return (
    <div>
      <div id="root ">
        {/* <SiderBar /> */}

        <div className="leftContainer newlogin">
          <div className="main-area" style={{ paddingTop: "80px" }}>
            <div class="login-card text-center">
              <h4 class="mb-4">LOGIN</h4>
              <img src="https://via.placeholder.com/50" alt="Logo" />
              {/* <form class="mt-3"> */}
              <div class="mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone number"
                  name="number"
                  onChange={hendleToChange}
                />
              </div>
              <div class="mb-3">
                <div class="input-group">
                  <input
                    type={passwordShow}
                    class="form-control"
                    placeholder="Password"
                    name="password"
                    onChange={hendleToChange}
                  />
                  <button
                    class="btn btn-outline-secondary"
                    onClick={() =>
                      setpasswordShow(
                        passwordShow == "password" ? "text" : "password"
                      )
                    }
                  >
                    {" "}
                    {passwordShow == "password" ? "SHOW" : "HIDE"}
                  </button>
                </div>
              </div>
              <div class="text-end mb-3">
                <a href="#" class="text-decoration-none">
                  Forgot Password?
                </a>
              </div>
              <button
                type="submit"
                class="btn btn-primary w-100"
                onClick={hendelotpVerfy}
              >
                LOGIN
              </button>
              {/* </form> */}
              <p class="my-3">or Login with</p>
              <button class="btn btn-outline-secondary btn-google">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                  alt="Google Logo"
                />
                Google
              </button>
              <p class="mt-3">
                Don't have an account?{" "}
                <Link to="/register" class="text-decoration-none">
                  Sign Up
                </Link>
              </p>
            </div>

            <div className=""></div>
          </div>
          {/* <div class="refer-footer">
             
            <a
              class="disabledButton btn btn-success btn-lg installbtn"
              href="../assest/image/drludo.apk"
              download
            >
              <img
                width="21px"
                src="../assest/image/apk.png"
                alt=""
                style={{ marginRight: "25px" }}
              />
              Install
              <img
                width="21px"
                src="../assest/image/download.png"
                style={{ marginLeft: "25px" }}
                alt=""
              />
            </a>
          </div> */}
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default LoginNew;
