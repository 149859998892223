import React from "react";
import { useNavigate } from "react-router-dom";

function FooterNew() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="" style={{ height: "100px" }}></div>
      <div className="footerContainerms">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col">
              <div
                onClick={() => {
                  navigate("/");
                }}
                className="icons d-flex justify-content-center align-items-center flex-column text-white mt-2"
              >
                <i className="fa fa-home " style={{ fontSize: "20px" }}></i>
                <p style={{ marginBottom: "1px" }}>Home</p>
              </div>
            </div>
            <div className="col">
              <div
                onClick={() => {
                  navigate("/earn");
                }}
                className="icons d-flex justify-content-center align-items-center flex-column text-white mt-2"
              >
                <i className="fa fa-rupee" style={{ fontSize: "20px" }}></i>
                <p style={{ marginBottom: "1px" }}>Earn</p>
              </div>
            </div>
            <div className="col">
              <div
                onClick={() => {
                  navigate("/lottery");
                }}
                className="icons d-flex justify-content-center align-items-center flex-column text-white mt-2"
              >
                <i className="fa fa-star" style={{ fontSize: "20px" }}></i>
                <p style={{ marginBottom: "1px" }}>Lottery</p>
              </div>
            </div>
            <div className="col">
              <div
                onClick={() => {
                  navigate("/wallet-new");
                }}
                className="icons d-flex justify-content-center align-items-center flex-column text-white mt-2"
              >
                <i className="fa fa-ticket" style={{ fontSize: "20px" }}></i>
                <p style={{ marginBottom: "1px" }}>Wallet</p>
              </div>
            </div>
            <div className="col">
              <div
                onClick={() => {
                  navigate("/setting");
                }}
                className="icons d-flex justify-content-center align-items-center flex-column text-white mt-2"
              >
                <i className="fa fa-smile-o" style={{ fontSize: "20px" }}></i>
                <p style={{ marginBottom: "1px" }}>Me</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterNew;
